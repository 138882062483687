/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SVVariantInfo { 
    /**
     * variant id
     */
    variantId: string;
    /**
     * variant type
     */
    variantType?: string;
    /**
     * variant id from sv vcf table
     */
    variantIDVCF?: string;
    /**
     * Consequence(s) + associated gene(s)
     */
    consequenceGenes?: string;
    /**
     * POS - END
     */
    position?: string;
    /**
     * size
     */
    size?: number;
    /**
     * cpx intervals
     */
    cpxIntervals?: string;
    /**
     * cpx type
     */
    cpxType?: string;
    /**
     * filter
     */
    filter?: string;
    /**
     * African Ancestry Allele Count
     */
    afrAlleleCount?: number;
    /**
     * African Ancestry Allele Number
     */
    afrAlleleNumber?: number;
    /**
     * African Ancestry Allele Frequency
     */
    afrAlleleFrequency?: number;
    /**
     * African Ancestry Homozygote Count
     */
    afrHomozygoteCount?: number;
    /**
     * East Asian Ancestry Allele Count
     */
    easAlleleCount?: number;
    /**
     * East Asian Ancestry Allele Number
     */
    easAlleleNumber?: number;
    /**
     * East Asian Ancestry Allele Frequency
     */
    easAlleleFrequency?: number;
    /**
     * East Asian Ancestry Homozygote Count
     */
    easHomozygoteCount?: number;
    /**
     * European Ancestry Allele Count
     */
    eurAlleleCount?: number;
    /**
     * European Ancestry Allele Number
     */
    eurAlleleNumber?: number;
    /**
     * European Ancestry Allele Frequency
     */
    eurAlleleFrequency?: number;
    /**
     * European Ancestry Homozygote Count
     */
    eurHomozygoteCount?: number;
    /**
     * Latin American Ancestry Allele Count
     */
    amrAlleleCount?: number;
    /**
     * Latin American Ancestry Allele Number
     */
    amrAlleleNumber?: number;
    /**
     * Latin American Ancestry Allele Frequency
     */
    amrAlleleFrequency?: number;
    /**
     * Latin American Ancestry Homozygote Count
     */
    amrHomozygoteCount?: number;
    /**
     * Middle Eastern Ancestry Allele Count
     */
    midAlleleCount?: number;
    /**
     * Middle Eastern Ancestry Allele Number
     */
    midAlleleNumber?: number;
    /**
     * Middle Eastern Ancestry Allele Frequency
     */
    midAlleleFrequency?: number;
    /**
     * Middle Eastern Ancestry Homozygote Count
     */
    midHomozygoteCount?: number;
    /**
     * South Asian Ancestry Allele Count
     */
    sasAlleleCount?: number;
    /**
     * South Asian Ancestry Allele Number
     */
    sasAlleleNumber?: number;
    /**
     * South Asian Ancestry Allele Frequency
     */
    sasAlleleFrequency?: number;
    /**
     * South Asian Ancestry Homozygote Count
     */
    sasHomozygoteCount?: number;
    /**
     * Other Ancestry Allele Count
     */
    othAlleleCount?: number;
    /**
     * Other Ancestry Allele Number
     */
    othAlleleNumber?: number;
    /**
     * Other Ancestry Allele Frequency
     */
    othAlleleFrequency?: number;
    /**
     * Other Homozygote Count
     */
    othHomozygoteCount?: number;
    /**
     * Total Allele Count
     */
    totalAlleleCount?: number;
    /**
     * Total Allele Number
     */
    totalAlleleNumber?: number;
    /**
     * Total Allele Frequency
     */
    totalAlleleFrequency?: number;
    /**
     * Total Homozygote Count
     */
    totalHomozygoteCount?: number;
}

